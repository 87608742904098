import { useEffect, useMemo, useState } from "react";

import { completeToken, redirectToMobi, saveEventHistory } from "../services/legacyService";
import { ConnectionStatus, EventType, SessionStatus, Signals } from "../shared/enums";
// import { uploadCurrentScreen } from "../providers/Storage";
// import { FirebaseProvider } from "../providers/Firebase";
// import { OracleStorageProvider } from "../providers/Oracle";

import { Publisher, Session, SignalEvent, StreamEvent, StreamManager, StreamManagerEvent } from "openvidu-browser-v2compatibility";
import { PAR_URL } from "../shared/constants";

export default function useRTCEvents(props: {
  connectionStatus: ConnectionStatus;
  captureCurrentScreen: any;
  setIsAccessDenied: any;
}) {
  const [subscribers, setSubscribers] = useState<StreamManager[]>([]);
  const [sessionStatus, setSessionStatus] = useState<SessionStatus>(SessionStatus.Awaiting);

  // const firebaseProvider = useMemo(() => new FirebaseProvider(), []);
  // const oracleProvider = useMemo(() => new OracleStorageProvider('staging-televistoria-images'), []);

  function subscribeToPublisherEvents(session: Session, publisher: Publisher) {
    // TODO: refresh publisher
    publisher.on("streamPlaying", (event: StreamManagerEvent) => {
      console.log(`publisher.streamPlaying`, event);
      saveEventHistory(`Segurado: Transmissão de áudio e vídeo estabelecida`, session.sessionId);
    });

    publisher.on("streamDestroyed", (event: StreamEvent) => {
      saveEventHistory(`Segurado: Transmissão de áudio e vídeo interrompida`, session.sessionId);
    });

    publisher.on("accessAllowed", () => {
      // props.setIsAccessAllowed(publisher.accessAllowed);
      console.warn(`[accessAllowed] ${session.sessionId}`);
      saveEventHistory(`Segurado: Acessos à câmera e microfone concedidos`, session.sessionId);
    });

    publisher.on("accessDenied", () => {
      props.setIsAccessDenied(true);
      console.warn(`[accessDenied] ${session.sessionId}`);

      saveEventHistory(`Segurado: Acessos à câmera e microfone não concedidos`, session.sessionId);
    });
  }

  async function uploadCurrentScreen(payload: any, blob: Blob) {

    if (!blob) {
      throw new Error('No file selected');
    }

    const xhr = new XMLHttpRequest();

    // console.log(`${PAR_URL}${payload.sessionId}/${payload.uniqueId}.png`);
    xhr.open('PUT', `${PAR_URL}${payload.sessionId}/${payload.uniqueId}.jpg`, true);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        console.log(`Upload progress: ${percentComplete}%`);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 200) {
        console.log('File uploaded successfully');
        return;
      } else {
        console.error('Upload failed', xhr.statusText);
        throw new Error(`Upload failed: ${xhr.statusText}`);
      }
    };

    xhr.onerror = () => {
      console.error('Upload error', xhr.statusText);
      throw new Error('Upload error');
    };

    xhr.send(blob);
  }

  async function subscribeToCustomEvents(session: Session, publisher: Publisher) {
    session.on("signal:screenshot", async (event: SignalEvent) => {
      console.info(`${event.data} frame has been requested`, event);
      console.log(`isActiveVideo:`, publisher.stream.videoActive);

      const uniqueId = `${publisher.session.sessionId}-${new Date().getTime()}`;
      const payload = { data: event.data, uniqueId, sessionId: publisher.session.sessionId };

      const canvasScreen = await generateCanvasForCapture(publisher);

      if (props.connectionStatus === ConnectionStatus.CONNECTED) {
        canvasScreen.toBlob(async (blob) => {
          try {
            if (blob) {
              await uploadCurrentScreen(payload, blob);
              await publisher.session.signal({
                data: uniqueId,
                type: `${Signals.ScreenShot}:${payload.data}`,
              });
            }
          } catch (error: any) {
            await publisher.session.signal({
              data: error.message,
              type: `${Signals.ScreenShot}:${event.data}`,
            });
          }
        });
      }
    });

    session.on("signal:finished", async (event: SignalEvent) => {
      try {
        session.disconnect();
        setSessionStatus(SessionStatus.Closed);
        console.log({ event });
        await completeToken(session.sessionId);
      } catch (error) {
        console.warn(error);
      }

      try {
        await completeToken(session.sessionId);
      } catch (error) {
        console.log(error);
      }

      redirectToMobi(session.sessionId);
    });
  }

  async function generateCanvasForCapture(publisher: Publisher): Promise<HTMLCanvasElement> {
    const canvas = document.createElement("canvas");
    console.log(`publisher.stream.videoActive`, publisher.stream.videoActive);

    const { video: videoFromPublisher } = publisher.videos[publisher.videos.length - 1];
    console.log(publisher.videos);
    console.log(videoFromPublisher)
    const videoFromDocument: HTMLVideoElement = document.getElementById(`local-video--undefined`) as HTMLVideoElement;

    const isSameVideo = await compareVideoHash(videoFromPublisher, videoFromDocument);

    const video = isSameVideo ? videoFromPublisher : videoFromDocument;
    console.log(`HTMLVideoElement:`, isSameVideo);
    const canvasScreen: HTMLCanvasElement = props.captureCurrentScreen(video, canvas);
    return canvasScreen;
  }

  async function compareVideoHash(pubElement: HTMLVideoElement, domElement: HTMLVideoElement) {
    const publisherHash = await generateVideoHash(pubElement);
    const documentHash = await generateVideoHash(domElement);

    return publisherHash === documentHash;
  }

  async function generateVideoHash(videoElement: HTMLVideoElement) {
    console.log(videoElement)
    const blob = await fetch(videoElement.src).then((response) => response.blob());

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function () {
        const buffer = reader.result;

        if (buffer instanceof ArrayBuffer) {
          crypto.subtle
            .digest("SHA-256", buffer)
            .then((hash) => {
              const hashArray = Array.from(new Uint8Array(hash));
              const hashedString = hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("");
              resolve(hashedString);
            })
            .catch((error) => {
              reject(error);
            });
        }
      };
      reader.readAsArrayBuffer(blob);
    });
  }

  useEffect(() => {
    console.log({ subscribers });
    console.log({ sessionStatus });

    subscribers.length > 0 ? setSessionStatus(SessionStatus.Active) : setSessionStatus(SessionStatus.Awaiting);
  }, [subscribers, sessionStatus]);

  return {
    subscribers,
    sessionStatus,
    setSubscribers,
    subscribeToCustomEvents,
    subscribeToPublisherEvents,
  };
}
