import { useEffect, useMemo, useState } from "react";

import { FirebaseProvider } from "../providers/Firebase";
import { OpenViduProvider } from "../providers/Openvidu";
import { createSession } from "../services/managerService";
import { DEFAULT_DEVICE_OPTIONS } from "../shared/constants";
import { Roles, EventType, ConnectionStatus } from "../shared/enums";

import {
  Session,
  Publisher,
  StreamEvent,
  Subscriber,
  ConnectionEvent,
  ExceptionEvent,
  SessionDisconnectedEvent,
  StreamPropertyChangedEvent,
  StreamManager,
  VideoElementEvent,
} from "openvidu-browser-v2compatibility";
import { saveEventHistory } from "../services/legacyService";

export default function useRTCSession(props: {
  connectionStatus: ConnectionStatus;
  setConnectionStatus: Function;
  sessionId: string;
  isMicActive: boolean;
  setIsMicActive: Function;
  hasVideoAvailable: boolean;
  handleErrorCallback: Function;
  defaultCamera?: MediaDeviceInfo;
  setSubscribers: Function;
}) {
  const { sessionId, setSubscribers, connectionStatus, setConnectionStatus } = props;

  const [isReady, setReadyness] = useState(false);
  const [session, setSession] = useState<Session>(null!);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isRecovering, setIsRecovering] = useState(false);
  const [isReconnecting, setIsReconnecting] = useState(false);

  const [publisher, setPublisher] = useState<Publisher>(null!);
  const openviduProvider = useMemo(() => new OpenViduProvider(), []);
  const firebaseProvider = useMemo(() => new FirebaseProvider(), []);
  const [recoverException, setRecoverException] = useState<ExceptionEvent | any>(null!);

  function subscribeToSessionEvents(session: Session) {
    session.on("streamCreated", (event: StreamEvent) => {
      const { clientData } = JSON.parse(event.stream.connection.data);
      console.log({ clientData, stream: event.stream.streamId });

      if (clientData === Roles.Analyst) {
        const subscriber: Subscriber = session.subscribe(event.stream, undefined!);

        console.info({ subscriber });
        setSubscribers((current: StreamManager[]) => [...current, subscriber]);
      }
    });

    session.on("streamDestroyed", (event: StreamEvent) => {
      const { clientData } = JSON.parse(event.stream.connection.data);
      console.log({ clientData, stream: event.stream.streamId });

      if (clientData === Roles.Analyst) {
        setSubscribers((current: StreamManager[]) => [
          ...current.filter((a) => a.stream.connection.connectionId !== event.stream.connection.connectionId),
        ]);
      }
    });

    session.on("connectionDestroyed", (event: ConnectionEvent) => {
      console.log(`[connectionDestroyed]`, event);
    });

    session.on("connectionCreated", (event: ConnectionEvent) => {
      console.log(`[connectionCreated]`, event.connection.connectionId, event.connection.data);
    });

    // https://docs.openvidu.io/en/stable/api/openvidu-browser-v2compatibility/classes/SessionDisconnectedEvent.html
    session.on("sessionDisconnected", (event: SessionDisconnectedEvent) => {
      console.log(`[sessionDisconnected]`, event);

      if (event.reason === "networkDisconnect") {
        // props.setConnectionStatus('disconnected');
        saveEventHistory(`Segurado: Perda de conexão de rede`, sessionId);

        console.log({ isRecovering, recoverException, publisher });

        setIsRecovering(true);
        setRecoverException({
          name: "ApplicationCustomError",
          message: "Segurado: Perda de conexão de rede",
          origin: "sessionDisconnected.networkDisconnect",
        });
      }

      if (event.reason === "disconnect") {
        saveEventHistory(`Segurado: Sessão desconectada`, sessionId);
        console.log({ isRecovering, recoverException, publisher });
      }
    });

    session.on(ConnectionStatus.RECONNECTING, () => {
      setIsReconnecting(true);
      setConnectionStatus(false);
      console.log(`[reconnecting]`);
      saveEventHistory(`Segurado: Reconectando à sessão`, sessionId);
    });

    session.on(ConnectionStatus.RECONNECTED, () => {
      setIsReconnecting(false);
      setConnectionStatus(true);
      console.log(`[reconnected]`);
      saveEventHistory(`Segurado: Conexão à sessão restabelecida`, sessionId);
    });

    session.on("streamPropertyChanged", (event: StreamPropertyChangedEvent) => {
      console.log("[streamPropertyChanged]", {
        event,
      });
    });

    // TODO: https://github.com/OpenVidu/openvidu.io-docs/blob/master/docs/advanced-features/automatic-reconnection.md
    session.on("exception", async (exception: ExceptionEvent) => {
      // props.handleErrorCallback(exception);
      // TODO: handle mutual exceptions ( publisher | subscriber ) -> wifi x 4g
      console.warn({ exception, isReconnecting });

      switch (exception.name) {
        case "ICE_CANDIDATE_ERROR":
          setRecoverException(exception);
          break;
        case "ICE_CONNECTION_FAILED":
          setRecoverException(exception);
          // setIsRecovering(true);
          break;
        case "ICE_CONNECTION_DISCONNECTED":
          setRecoverException(exception);
          // setIsRecovering(true);
          break;
        case "NO_STREAM_PLAYING_EVENT":
          setRecoverException(exception);
          break;
      }

      firebaseProvider.sendUserEvent(EventType.EXCEPTION, {
        description: `${exception.name}|${exception.message}`,
        fatal: true,
      });
    });
  }

  async function connectSession(session: Session, publisher: Publisher) {
    try {
      const { token } = await createSession(sessionId);

      await session.connect(token, { clientData: Roles.Insured });
      await session.publish(publisher);
      setReadyness(true);

      firebaseProvider.sendUserEvent(EventType.LEVEL_START, {
        level_name: "Ingresso à sala de vídeo estabelecido",
      });
    } catch (error) {
      props.handleErrorCallback(error);
    }
  }

  useEffect(() => {
    if (!publisher && props.defaultCamera) {
      // TODO: Safari only allows access to devices after getUserMedia is triggered

      const deviceConfig = {
        ...DEFAULT_DEVICE_OPTIONS,
        videoSource: props.defaultCamera.deviceId,
      };

      const pub = openviduProvider.initializePublisher("local-media", deviceConfig, props.handleErrorCallback);

      pub.on("videoElementCreated", (event: VideoElementEvent) => {
        console.log(`publisher.videoElementCreated`, event);
        console.log(`Segurado: Tela de transmissão de vídeo criada`);
      });

      pub.on("videoElementDestroyed", (event: VideoElementEvent) => {
        console.log(`publisher.videoElementDestroyed`, event);
        console.log(`Segurado: Tela de transmissão de vídeo removida`);
      });

      setPublisher(pub);
    }
  }, [publisher, props.defaultCamera]);

  useEffect(() => {
    if (connectionStatus === ConnectionStatus.CONNECTED && publisher && !session) {
      try {
        const session = openviduProvider.initializeSession();
        subscribeToSessionEvents(session);

        setSession(session);
        setIsConnecting(true);
      } catch (error: any) {
        props.handleErrorCallback(error);
      }
    }
  }, [publisher, session, connectionStatus]);

  useEffect(() => {
    if (isConnecting && session && publisher) {
      const connect = async () => {
        try {
          await connectSession(session, publisher);
          setIsConnecting(false);
        } catch (error) {
          props.handleErrorCallback(error);
        }
      };

      connect();
    }
  }, [isConnecting, session, publisher]);

  useEffect(() => {
    console.log({ recoverException, isRecovering, connectionStatus });
    if (recoverException && isRecovering && connectionStatus === ConnectionStatus.CONNECTED) {
      const fallback = () => {
        setTimeout(async () => {
          await connectSession(session, publisher);
          setRecoverException(null);
          setIsRecovering(false);
        }, 1500);
      };

      fallback();
    }
  }, [recoverException, isRecovering, connectionStatus]);

  return {
    isReady,
    session,
    publisher,
    isRecovering,
    setPublisher,
    recoverException,
    openviduProvider,
  };
}
