import { Publisher, Session } from 'openvidu-browser-v2compatibility';
import { ToolbarComponent } from '../ToolbarComponent/Toolbar';
import ChatMessageView from '../ChatComponent/ChatMessageView';

export default function VideoRoomControls(props: {
  session: Session;
  publisher: Publisher;
  chat: { isActiveChat: boolean; setIsActiveChat: Function };
  audio: { isActiveAudio: boolean; setIsMicActive: Function };
  messages: { hasUnreadMessages: boolean; setUnreadMessages: Function };
  video: { isActiveVideo: boolean; setIsCamActive: Function; camPositionChanged: Function };
}) {
  return (
    <div>
      <div className="controls">
        <ChatMessageView
          session={props.session}
          isActiveChat={props.chat.isActiveChat}
          setUnreadMessages={props.messages.setUnreadMessages}
        />
        <ToolbarComponent
          session={props.session}
          publisher={props.publisher}
          sessionId={props.session.sessionId}
          isActiveChat={props.chat.isActiveChat}
          isActiveAudio={props.audio.isActiveAudio}
          isActiveVideo={props.video.isActiveVideo}
          setIsMicActive={props.audio.setIsMicActive}
          setIsCamActive={props.video.setIsCamActive}
          setIsActiveChat={props.chat.setIsActiveChat}
          hasUnreadMessages={props.messages.hasUnreadMessages}
          camPositionChanged={props.video.camPositionChanged}
        />
      </div>
    </div>
  );
}
