import { useCallback, useEffect, useMemo, useState } from "react";
import "../components/VideoRoomComponent/Participant.css";
import "./Conference.css";
import "../App.css";

import { Environments, EventType } from "../shared/enums";
import useRTCConnection from "../hooks/useRTCConnection";

import { useLocation, useNavigate } from "react-router-dom";
import useRTCSession from "../hooks/useRTCSession";
import useRTCEvents from "../hooks/useRTCEvents";

import useCameraDevice from "../hooks/useCameraDevice";
import { FirebaseProvider } from "../providers/Firebase";
import Header from "../components/HeaderComponent/Header";
import { OpenViduErrorName, Publisher, Session } from "openvidu-browser-v2compatibility";

import { ENVIRONMENT_CONTEXT } from "../shared/constants";
import useDeviceCapabilities from "../hooks/useDeviceCapabilities";
import ConnectionComponent from "../components/ConnectionComponent/Connection";

import ErrorBoundaryComponent from "../components/ErrorComponent/ErrorBoundaryWrapper";
import ErrorPermission from "../components/ErrorComponent/ErrorPermission";
import { IErrorInfo } from "../shared/interfaces";

import SubscriberComponent from "../components/VideoRoomComponent/Subscriber";
import PublisherComponent from "../components/VideoRoomComponent/Publisher";
import VideoRoomTransition from "../components/VideoRoomComponent/VideoTransition";
import VideoRoomControls from "../components/VideoRoomComponent/VideoControls";

export default function VideoConferenceContainer() {
  const [isMicActive, setIsMicActive] = useState(true);
  const [isCamActive, setIsCamActive] = useState(true);
  const [isActiveChat, setIsActiveChat] = useState(false);
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const [hasUnreadMessages, setUnreadMessages] = useState(false);

  const [hasSessionError, setHasError] = useState(false);
  const [sessionErrorContext, setErrorContext] = useState<IErrorInfo>(null!);

  const navigate = useNavigate();
  const location = useLocation();

  const { sessionId } = location.state;
  const firebaseProvider = useMemo(() => new FirebaseProvider(), []);

  // TODO: Decouple Event into substages of connection
  firebaseProvider.sendUserEvent(EventType.PAGE_VIEW, {
    page_location: "/room",
    page_encoding: "",
    page_title: "Vídeo Conferência",
    user_agent: "",
    client_id: sessionId,
    language: "",
  });

  function handleErrorCallback(error: Error) {
    if (error) {
      console.log({ error });

      let errorInfo: IErrorInfo = { name: "", detail: "" };

      switch (error.name) {
        case OpenViduErrorName.BROWSER_NOT_SUPPORTED:
          errorInfo.name = "Seu navegador não é suportado";
          break;
        case OpenViduErrorName.INPUT_VIDEO_DEVICE_NOT_FOUND:
          errorInfo.name = "Não foi possível identificar a sua câmera";
          break;
        case OpenViduErrorName.INPUT_AUDIO_DEVICE_NOT_FOUND:
          errorInfo.name = "Não foi possível identificar o seu microfone";
          break;
        case OpenViduErrorName.DEVICE_ACCESS_DENIED:
          errorInfo.name = "Por favor autorize o acesso ao seu dispositivo de áudio e vídeo";
          break;
        default:
          errorInfo.name = "Estamos com dificuldades em estabelecer a sua conexão";
      }

      if (ENVIRONMENT_CONTEXT === Environments.DEVELOPMENT) {
        errorInfo.detail = `${error.message} | ${error.name} | ${error.stack}`;
      }

      setErrorContext(errorInfo);
      setHasError(true);

      throw new Error(errorInfo.name);
    }
  }

  function onResetState() {
    try {
      session.disconnect();
      navigate("/restore", { state: { sessionId, sessionErrorContext } });
    } catch (error: unknown) {
      console.log({ error });
      navigate("/restore", { state: { sessionId, sessionErrorContext } });
    }
  }

  const { connectionStatus, setConnectionStatus } = useRTCConnection();

  const {
    defaultCamera,
    hasVideoAvailable,
    currentCameraType,
    backCameraDevices,
    frontCameraDevices,
    updateCurrentCamera,
    captureCurrentScreen,
  } = useDeviceCapabilities({ sessionId, setIsAccessDenied });

  const { subscribers, sessionStatus, setSubscribers, subscribeToCustomEvents, subscribeToPublisherEvents } =
    useRTCEvents({ setIsAccessDenied, captureCurrentScreen, connectionStatus });

  const { isReady, session, publisher, setPublisher, openviduProvider, recoverException } = useRTCSession({
    connectionStatus,
    sessionId,
    isMicActive,
    defaultCamera,
    setSubscribers,
    setIsMicActive,
    hasVideoAvailable,
    handleErrorCallback,
    setConnectionStatus,
  });

  const { camPositionChanged } = useCameraDevice({
    handleErrorCallback,
    updateCurrentCamera,
    backCameraDevices,
    frontCameraDevices,
    currentCameraType,
    settings: {
      isActiveAudio: isMicActive,
      isActiveVideo: isCamActive,
    },
    provider: openviduProvider,
    setPublisher,
    publisher,
    session,
  });

  const handleResetState = useCallback(onResetState, [navigate, session, sessionErrorContext, sessionId]);

  const subscribeToRoomEvents = useCallback(
    (session: Session, publisher: Publisher) => {
      // TODO: subscribeToCameraDevices
      console.log("Context", { environment: ENVIRONMENT_CONTEXT });

      subscribeToCustomEvents(session, publisher);
      subscribeToPublisherEvents(session, publisher);
    },
    [subscribeToCustomEvents, subscribeToPublisherEvents]
  );

  useEffect(() => {
    if (hasSessionError && sessionErrorContext) {
      handleResetState();
    }
  }, [hasSessionError, sessionErrorContext, handleResetState]);

  useEffect(() => {
    if (session && publisher && !isReady) {
      console.log("Permissions", { session, publisher, isReady });
      subscribeToRoomEvents(session, publisher);
    }
  }, [session, publisher, isReady]);

  return (
    <div className='App'>
      <Header token={sessionId!} />
      <div id='local-media' className='local-media'></div>
      {!isReady && !isAccessDenied && <ConnectionComponent sessionId={sessionId} />}
      {isAccessDenied && <ErrorPermission onResetState={handleResetState} />}
      <ErrorBoundaryComponent
        container={
          <>
            {isReady && (
              <div>
                <VideoRoomTransition
                  roomStatus={sessionStatus}
                  exception={recoverException!}
                  connStatus={connectionStatus}
                />
                {/* <PublisherComponent key={`pub-${Date.now()}`} publisher={publisher} /> */}
                {subscribers.map((subscriber) => (
                  <SubscriberComponent key={`sub-${Date.now()}`} subscriber={subscriber} />
                ))}
                <VideoRoomControls
                  session={session}
                  publisher={publisher}
                  chat={{ isActiveChat, setIsActiveChat }}
                  messages={{ hasUnreadMessages, setUnreadMessages }}
                  audio={{ isActiveAudio: isMicActive, setIsMicActive }}
                  video={{ isActiveVideo: isCamActive, setIsCamActive, camPositionChanged }}
                />
              </div>
            )}
          </>
        }
        onResetState={handleResetState}
      />
    </div>
  );
}
